import { sentryNext } from '@packages/sentry-next'

const { init, captureException } = sentryNext

const captureRelayError = (error: any) => {
  // extras errors and adds them to sentry extra
  sentryNext.captureException(JSON.stringify(error), {
    errors: error.source && [...error.source.errors.map((i: any) => i.message)],
    variables: error.variables,
  })
}

export { init, captureException, captureRelayError }
